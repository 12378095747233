import {
  loginByUsername,
  loginAdminByUsername,
  logout,
  getUserInfo,
  getCustomerAvatar,
} from "@/api/login";
import {
  getCustomerGroups,
  checkCostIsCorrectApi,
  getMessagesList,
  getWebsitesList,
} from "@/api/admin";
import {
  getToken,
  setToken,
  removeToken,
  getAdminToken,
  setAdminToken,
  removeAdminToken,
} from "@/utils/auth";
import searchFilter from "@/mixins/searchFilter";
import _ from "lodash";
import { getAllBalancesType } from "@/api/payments";
import { getStockId, getAllInventories } from '@/api/cart'
import Laserhub from "../../features/laserhub/domain/entities/laserhub";

const user = {
  state: {
    user: "",
    stockId: "",
    token: getToken(),
    adminToken: getAdminToken(),
    roles: [],
    sources: [],
    drawerRight: false,
    customerGroups: [],
    extension_attributes: [],
    paymentTypes: [
      { value: "0", label: "PayPal" },
      { value: "1", label: "Prepay Deposit" },
      { value: "2", label: "Monthly Invoice" },
    ],
    payment: [],
    currencies: [
      { value: "USD", label: "USD" },
      { value: "CAD", label: "CAD" },
    ],
    loader: false,
    customerLogo: "",
    errorNotify: false,
    errorCount: 0,
    productsCostsList: [],
    adminMessages: [],
    websites: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ADMIN_TOKEN: (state, token) => {
      state.adminToken = token;
    },
    SET_DRAWER_RIGHT: (state, status) => {
      state.drawerRight = status;
    },
    SET_ROLES: (state, roles) => {
      state.roles.push(roles);
    },
    SET_EXTENSION_ATTRIBUTES: (state, extension_attributes) => {
      state.extension_attributes = extension_attributes;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_STOCK_ID: (state, stockId) => {
      state.stockId = stockId;
    },
    SET_SOURCES: (state, sources) => {
      state.sources = sources;
    },
    SET_PAYMENT: (state, payment) => {
      state.payment = payment;
    },
    SET_CUSTOMER_GROUPS: (state, groups) => {
      state.customerGroups = groups;
    },
    SET_PRODUCTS_CORRECT_COST_LIST: (state, list) => {
      state.productsCostsList = list;
    },
    SET_LOADER: (state, item) => {
      state.loader = item;
    },
    SET_USER_LOGO: (state, item) => {
      state.customerLogo = item;
    },
    SET_ADMIN_MESSAGES: (state, messages) => {
      state.adminMessages = messages;
    },
    SET_ERROR_NOTIFY: (state, bool) => {
      state.errorNotify = bool;
      if (bool) {
        state.errorCount++;
      } else {
        state.errorCount = 0;
      }
    },

    SET_WEBSITE_LIST: (state, list) => {
      state.websites = list;
    },
  },

  actions: {
    setLoader({ commit }, item) {
      commit("SET_LOADER", item);
    },
    getCustomerGroup({ commit }) {
      return new Promise((resolve, reject) => {
        getCustomerGroups()
          .then((response) => {
            const data = response.data;

            commit("SET_CUSTOMER_GROUPS", data.items);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    checkCostIsCorrect({ commit }) {
      return new Promise((resolve, reject) => {
        checkCostIsCorrectApi()
          .then((response) => {
            const data = response.data;

            commit("SET_PRODUCTS_CORRECT_COST_LIST", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    checkMessages({ commit }) {
      let data = {
        pageNumber: 1,
      };
      getMessagesList(data).then((response) => {
        if (response.data) {
          commit("SET_ADMIN_MESSAGES", response.data.notifications);
        }
      });
    },
    drawerRightToggle({ commit, state }, drawer) {
      if (drawer) {
        commit("SET_DRAWER_RIGHT", drawer);
      } else {
        commit("SET_DRAWER_RIGHT", false);
      }
    },
    login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        loginByUsername(username, userInfo.password)
          .then((response) => {
            const data = response.data;
            commit("SET_TOKEN", data);
            setToken(data);

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loginAdmin({ commit, dispatch }, userInfo) {
      const username = userInfo.username.trim();
      dispatch('fetchInventoryRelatedInfo')
      return new Promise((resolve, reject) => {
        loginAdminByUsername(username, userInfo.password)
          .then((response) => {
            const data = response.data;
            commit("SET_ADMIN_TOKEN", data);
            setAdminToken(data);

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchInventoryRelatedInfo({ commit }, userInfo) {
      new Promise((resolve, reject) => {
        getAllInventories()
            .then((response) => {
              if (!response.data) {
                reject("error");
              }
              let data = response.data.items;
              let sources = [];
              for (const item of data) {
                if (item.source_code == 'default') {
                  continue;
                }
                sources.push(new Laserhub(item.source_code, item.name))
              }
              commit("SET_SOURCES", sources);
            })
            .catch((error) => {
              reject(error);
            });
      });
      new Promise((resolve, reject) => {
        getStockId()
            .then((response) => {
              if (!response.data) {
                reject("error");
              }
              let data = response.data;
              commit("SET_STOCK_ID", data.stock_id);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    GetPaymentTypes({ commit, state, dispatch }) {
      new Promise((resolve, reject) => {
        getAllBalancesType()
          .then((response) => {
            if (!response.data) {
              reject("error");
            }
            let data = response.data;
            commit("SET_PAYMENT", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetUserInfo({ commit, state, dispatch }) {
      new Promise((resolve, reject) => {
        getAllBalancesType()
          .then((response) => {
            if (!response.data) {
              reject("error");
            }
            let data = response.data;
            commit("SET_PAYMENT", data);
          })
          .catch((error) => {
            reject(error);
          });
      });
      new Promise((resolve, reject) => {
        getAllInventories()
          .then((response) => {
            if (!response.data) {
              reject("error");
            }
            let data = response.data.items;
            let sources = [];
            for (const item of data) {
              if (item.source_code == 'default') {
                continue;
              }
              sources.push(new Laserhub(item.source_code, item.name))
            }
            commit("SET_SOURCES", sources);
          })
          .catch((error) => {
            reject(error);
          });
      });
      new Promise((resolve, reject) => {
        getStockId()
          .then((response) => {
            if (!response.data) {
              reject("error");
            }
            let data = response.data;
            commit("SET_STOCK_ID", data.stock_id);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((response) => {
            if (!response.data) {
              reject("error");
            }
            const data = response.data;
            commit("SET_USER", data);
            resolve(data);
          })
          .catch((error) => {
            removeToken();
            reject(error);
          });
      });
    },
    LogOut({ commit, state }) {
      commit("SET_TOKEN", "");
      commit("SET_ADMIN_TOKEN", "");
      commit("SET_ROLES", []);
      removeToken();
      removeAdminToken();
    },
    FedLogOut({ commit }) {
      commit("SET_TOKEN", "");
      commit("SET_ADMIN_TOKEN", "");
      commit("SET_ROLES", []);
      removeToken();
      removeAdminToken();
    },
    getCustomerLogo({ commit }) {
      let id = this.getters.userId;
      return new Promise((resolve, reject) => {
        getCustomerAvatar(id)
          .then((response) => {
            if (!response.data) {
              reject("error");
            }
            const data = response.data;
            let url = _.head(data);
            commit("SET_USER_LOGO", url.url);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setErrorNotify({ commit }, bool) {
      commit("SET_ERROR_NOTIFY", bool);
    },
    getWebsites({ commit }) {
      return new Promise((resolve, reject) => {
        getWebsitesList()
          .then((response) => {
            let data = _.remove(response.data, (obj) => {
              if (process.env.VUE_APP_TYPE == "profit") {
                return obj.code != "admin";
              } else {
                return obj.code == process.env.VUE_APP_CODE;
              }
            });

            data = _.map(data, (item) => {
              return {
                value: item.id,
                code: item.code,
                label: item.name,
              };
            });
            commit("SET_WEBSITE_LIST", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default user;
