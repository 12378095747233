import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters(['roles', 'userId', 'websites', 'getSearchTerm']),
        searchTerm: {
            get() {
                return this.getSearchTerm;
            },
            set(newValue) {
                this.$store.dispatch("setSearch", newValue);
            }
        },
        website_id: {
            get() {
                return this.$store.getters.filtersStore.website_id;
            },
            set(newValue) {
                this.$store.dispatch("setFilterWebId", newValue);
            }
        },
        filterDate: {
            get() {
                return this.$store.getters.filtersStore.filterDate;
            },
            set(newValue) {
                this.$store.dispatch("setFilterDate", newValue);
            }
        },
        filter: {
            get() {
                return this.$store.getters.filtersStore.filters;
            },
        },

        filterArray: function () {
            let filtered = [];
            if (this.roles.indexOf("customer") >= 0 && !this.hideSearchCustomerId) {
                filtered.push({
                    filters: [
                        {
                            field: 'main_table.customer_id',
                            value: this.userId,
                            conditionType: 'eq'
                        }
                    ]
                });
            }
            if (this.filterDate && this.filterDate.length > 0) {
                let dates = [...this.filterDate]
                // Add "to" date, prevent empty array key
                if(!dates[1]){
                    dates.push(this.$moment().format('YYYY-MM-DD'))
                }
                // Set asc order for dates
                dates = dates.sort(function(a,b){
                    return new Date(a) - new Date(b);
                });
                filtered.push({
                    filters: [
                        {
                            field: "main_table.created_at",
                            value: this.$moment(dates[0] + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
                            conditionType: "from"
                        }
                    ]
                });
                filtered.push({
                    filters: [
                        {
                            field: "main_table.created_at",
                            value: this.$moment(dates[1] + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
                            conditionType: "to"
                        }
                    ]
                });
            }
            if (!this.website_id && this.isAdmin) {
                if (this.websites.length > 0) {
                    this.website_id = this._.head(this.websites);

                    filtered.push({
                        filters: [
                            {
                                field: ((this.$route.name == 'paymentsList') ? 'main_table.' : '') + 'website_id',
                                value: this._.head(this.websites).value,
                                conditionType: "eq"
                            }
                        ]
                    });
                }
            }
            else if (this.website_id && this.isAdmin) {
                filtered.push({
                    filters: [
                        {
                            field: ((this.$route.name == 'paymentsList') ? 'main_table.' : '') + 'website_id',
                            value: this.website_id.value,
                            conditionType: "eq"
                        }
                    ]
                });
            }
            if (this.isManager) {
                filtered.push({
                    filters: [
                        {
                            field: ((this.$route.name == 'paymentsList') ? 'main_table.' : '') + 'website_id',
                            value: process.env.VUE_APP_STORE_ID,
                            conditionType: "eq"
                        }
                    ]
                });
            }
            if (this.filter) {
                this._.forEach(this.filter, function (value, key) {
                    if (value.value) {
                        switch (key) {
                            default:
                                filtered.push({
                                    filters: [
                                        {
                                            field: key,
                                            value: value.value,
                                            conditionType: "eq"
                                        }
                                    ]
                                });
                                break;
                        }
                    }
                });

            }
            if (this.searchTerm && this.searchFields) {
                let searchField = this.searchTerm
                let filters = []
                this._.forEach(this.searchFields, function (value) {
                    filters.push({
                        field: value,
                        value: `%${searchField}%`,
                        conditionType: "like"
                    })
                });
                filtered.push({filters: filters})
            }

            return filtered;
        }
    },

    beforeRouteLeave(to, from, next) {
        this.$store.dispatch("setSearch", "");
        next();
    }
}
