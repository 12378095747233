import { cartAdd, cartAddItems, updateCartItem, cartGet, cartGetItems, getCartSumTotal, getAllInventories, getStockId } from '@/api/cart'
import { saveOrderOnly } from '@/api/orders'
import LaserhubClassesFactory from "@/features/laserhub/factories/laserhub_classes_factory";
import RetriveAvailableLaserhubsParameters
  from "@/features/laserhub/domain/usecases/parameters/retrieve_available_laserhubs_parameters";
import NotInStockError from "@/core/errors/laserhub/not_in_stock_error";
import CantShipTogetherError from "@/core/errors/laserhub/cant_ship_together_error";
import CantFetchLaserhubsError from "@/core/network_errors/laserhub/cant_fetch_laserhubs";

const cart = {
  state: {
    cartId: '',
    cartItems: '',
    cartTotalSum: '',
    stores: [],
    selectedLaserhubId: '',
    fullCart: {},
    delayOrder: false,
    hasTryNowOrders: false,
    isCartEditing: false,
    laserhubLoadingError: null,
    availableLaserhubs: [],
    fromUpdate: false,
  },

  mutations: {
    'ADD_CART'(state, id) {
      state.cartId = id
    },
    'SET_ACTIVE_CART'(state, id) {
      state.cartId = id
    },
    'GET_CART'(state, obj) {
      state.fullCart = obj
      state.cartId = obj.id
    },
    'GET_CART_ITEMS'(state, items) {
      state.cartItems = items
    },
    'CLEAR_CART'(state) {
      state.cartId = ''
      state.cartItems = ''
      state.cartTotalSum = ''
      state.fullCart = {}

    },
    'GET_CART_TOLAL_SUM'(state, sums) {
      state.cartTotalSum = sums
    },
    'UPDATE_CART'(state, { item, quantity, isAdd }) {
      const record = state.cartItemList.find(element => element.id == item.id)
      if (record) {
        if (isAdd) {
          record.quantity += quantity
        } else {
          record.quantity = quantity
        }
      } else {
        state.cartItemList.push({
          ...item,
          quantity
        });
      }
    },
    'SET_CART'(state, productList) {
      if (productList) {
        state.cartItemList = productList
      }
    },
    'REMOVE_CART_ITEM'(state, { item }) {
      const record = state.cartItemList.find(element => element.id == item.id)
      state.cartItemList.splice(state.cartItemList.indexOf(record), 1)
    },
    'DELAY_ORDER'(state, bool) {
      state.delayOrder = bool;
    },
    'CHANGE_HAS_TRY_NOW_ORDERS'(state, bool) {
      state.hasTryNowOrders = bool;
    },
    'CHANGE_IS_CART_EDITING'(state, bool) {
      state.isCartEditing = bool;
    },
    'SET_SELECTED_LASERHUB_ID'(state, selectedLaserhubId) {
      state.selectedLaserhubId = selectedLaserhubId;
    },
    'SET_INVENTORY_SOURCES' (state, sources) {
      state.sources = sources;
    },
    'AVAILABLE_LASERHUBS' (state, data){
      state.availableLaserhubs = data;
    },
    'LASERHUB_ERROR'(state,message){
      state.laserhubLoadingError = message;
    },
    'SET_FROM_UPDATE'(state, status){
      state.fromUpdate = status;
    }
  },
 
  actions: {
    setFromUpdate({ commit }, status) {
      commit("SET_FROM_UPDATE", status);
    },
    createCart: ({ commit }) => {
      return new Promise((resolve, reject) => {
        cartAdd().then(response => {
          const data = response.data
          commit('CHANGE_HAS_TRY_NOW_ORDERS', false)
          commit('CHANGE_IS_CART_EDITING', false)
          commit('ADD_CART', data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loadLaserhubs:({commit, dispatch, getters, state}) => {
      const { userObject, stockId, allLaserhubs } = getters;
      const address = Object.assign({}, userObject.addresses[0]);
      commit('LASERHUB_ERROR', null)
      dispatch('setLoader', true)
      return new Promise((resolve, reject) => {
        let selector = LaserhubClassesFactory.getRetrieveAvailableLaserhubsUsecase();
        const params = new RetriveAvailableLaserhubsParameters(state.cartItems, address, stockId, allLaserhubs)
        selector.run(params).then(response => {
          commit('AVAILABLE_LASERHUBS',response)
          dispatch('setLoader', false)
        }).catch(error => {
          dispatch('setLoader', false)
          if (error instanceof NotInStockError || error instanceof CantShipTogetherError || error instanceof CantFetchLaserhubsError) {
            commit('LASERHUB_ERROR',error)
          } else {
            commit('LASERHUB_ERROR',error)
          }
          reject(error)
        }).finally(
            dispatch('setLoader', false)
        )
      })
    },
    clearCreateCart: ({ commit, dispatch }) => {
      commit('CLEAR_CART')
      dispatch('createCart')
    },

    setActiveCart: ({ commit, dispatch }, id) => {
      commit('SET_ACTIVE_CART', id)
      dispatch('getCartTotalSum')
    },

    clearCart: ({ commit }) => {
      commit('CLEAR_CART')
    },
    removeItemInCart: ({ commit }, { item }) => {
      commit('REMOVE_CART_ITEM', { item })
    },

    updateCart: ({ state, dispatch }, item) => {
      dispatch('setLoader', true)
      item.quote_id = state.cartId

      return new Promise((resolve, reject) => {
        cartAddItems(item).then(response => {
          const data = response.data

          dispatch('getCartItems')

          dispatch('setLoader', false)

        }).catch(error => {
          dispatch('setLoader', false)
          reject(error)
        })
      })

    },
    updateCartItem: ({ commit, state, dispatch }, obj) => {
      dispatch('setLoader', true)
      return new Promise((resolve, reject) => {
        updateCartItem(obj.item, obj.type).then(response => {
          dispatch('getCartItems')

          dispatch('setLoader', false)
        }).catch(error => {
          dispatch('setLoader', false)
          reject(error)
        })
      })
    },
    updateCartItemFromOrderPage: ({ commit, state, dispatch }, obj) => {
      dispatch('setLoader', true)
      return new Promise((resolve, reject) => {
        updateCartItem(obj.item, obj.type).then(response => {
          dispatch('setLoader', false)
        })
        .then(()=> {
            saveOrderOnly(obj.orderId).then(response => { 
              obj.callAfterDelete();
              resolve(response.data)        
            }).catch(error => {
              this.$store.dispatch('pushErrorNotify', { error: error });
              reject(error);
            });
        })
        .then(()=> {
          dispatch('getCartItems')
          resolve()
        })
        .catch(error => {
          dispatch('setLoader', false)
          reject(error)
        })
      })
    },
    getCartItems: ({ commit, dispatch, getters, state }) => {
      dispatch('setLoader', true)
      const{ isProfitWebSite } = getters;
      return new Promise((resolve, reject) => {
        cartGetItems().then(response => {
          const data = response.data
          dispatch('getCart')
          commit('GET_CART_ITEMS', data)
          dispatch('getCartTotalSum')
          if(isProfitWebSite && state.fromUpdate){
            dispatch('loadLaserhubs')
          }
          dispatch("setFromUpdate", false)
          dispatch('setLoader', false)
          resolve(data)
        }).catch(error => {
          dispatch('setLoader', false)
          reject(error)
        })
      })
    },
    getCart: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        cartGet().then(response => {
          const data = response.data
          commit('GET_CART', data)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCartTotalSum: ({ commit, dispatch }) => {
      dispatch('setLoader', true)
      return new Promise((resolve, reject) => {
        getCartSumTotal().then(response => {
          const data = response.data
          commit('GET_CART_TOLAL_SUM', data)
          dispatch('setLoader', false)
          resolve(data)
        }).catch(error => {
          dispatch('setLoader', false)
          reject(error)
        })
      })
    },
    delayOrder: ({ commit }, bool) => {
      commit('DELAY_ORDER', bool)
    },
    changeHasTryNowOrders: ({ commit }, bool) => {
      commit('CHANGE_HAS_TRY_NOW_ORDERS', bool)
    },
    changeIsCartEditing: ({ commit }, bool) => {
      commit('CHANGE_IS_CART_EDITING', bool)
    },
  },

}

export default cart
